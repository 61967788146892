@import "scss/bootstrap-customization";
@import "~bootstrap/scss/bootstrap";
@import "scss/custom-range";
@import "scss/custom-control";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

html, body, #root {
  height: 100%;
}

.form-control.form-control-smallish {
  font-size: 14px;
  padding: 0.25rem 1.5rem 0.25rem 0.5rem;
  height: calc(1.5em + 0.5rem + 2px);
}